import React from 'react';
import Navbar from '../components/Navbar';
import Hero from '../components/Hero';
import VideoPlayer from '../components/VideoPlayer';
import AboutContainer from '../components/AboutContainer';
import TecnologiesContainer from '../components/TecnologiesContainer';
import Contacts from '../components/Contacts';

function Home() {
  return (
    <>
      <Hero></Hero>
      {/* Pasa el enlace del video, el ancho y el alto directamente como props */}
      <AboutContainer></AboutContainer>
      <VideoPlayer
        youtubeUrl='https://youtu.be/__EVs8jKXXU?si=aRaQ2V6u-iY4E-Cz'
        width='1196px'
        height='673px'
      />
      <TecnologiesContainer></TecnologiesContainer>
      <Contacts></Contacts>
      
    </>
  );
}

export default Home;
