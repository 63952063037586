import React from 'react';
import '../styles/Contacts.css'; // Importa el archivo CSS
import PerfilPhoto from '../Images/PerfilPhoto.png'; // Importa la imagen
import 'bootstrap-icons/font/bootstrap-icons.css';

function Contacts() {
  return (
    <div className='ContactsContainer'>
      <div className='LeftContainer'>
        <img src={PerfilPhoto} className='Photo' alt="Perfil" />
      </div>
      <div className='RightContainer'>
        <h2>Join me on my journey of creativity and innovation! Follow me to stay updated with my latest projects, insights, and adventures</h2>
        <div className='IconLinks'>
          <a href='https://www.linkedin.com/in/diego-galan-6b513426a' target="_blank" rel="noopener noreferrer">
            <i className='bi bi-linkedin IconsContact'></i>
          </a>
          <a href='https://github.com/DiegoG1117' target="_blank" rel="noopener noreferrer">
            <i className='bi bi-github IconsContact'></i>
          </a>
          <a href='https://www.facebook.com/diegoricardo.galanllanos' target="_blank" rel="noopener noreferrer">
            <i className='bi bi-facebook IconsContact'></i>
          </a>
          <a href='https://www.instagram.com/diegogalanllanos/' target="_blank" rel="noopener noreferrer">
            <i className='bi bi-instagram IconsContact'></i>
          </a>
          <a href='https://www.youtube.com/channel/UCZcg_ljk1MMp-GWH50eTV-A' target="_blank" rel="noopener noreferrer">
            <i className='bi bi-youtube IconsContact'></i>
          </a>
          <a href='mailto:diegogalan33440@gmail.com'>
            <i className='bi bi-envelope IconsContact'></i>
          </a>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
