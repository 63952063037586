// UXDesigner.js
import React from 'react';
import Navbar from '../components/Navbar';
import InfoContainer from '../components/InfoContainer';
import InfoContainerInv from '../components/InfoContainerInv';

function UXDesigner() {
    return (
      <>
      <InfoContainer 
        youtubeUrl='https://youtu.be/CasvuY_f5Ss?si=Vu8F8H0GYr18uQ5-'
        title='TGE Figma Implementation'
        description='In this project, I created a Figma representation of an application designed to work with kids with ADHD. We conducted usability testing to identify how kids interact with the app and how we could improve it for them.'
        imgUrl='https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg'
        imgUrl2='https://www.svgrepo.com/show/13671/youtube.svg'
        imgUrl3='https://cdn.worldvectorlogo.com/logos/freepik.svg'
        imgUrl4='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Adobe_Illustrator_CC_icon.svg/2101px-Adobe_Illustrator_CC_icon.svg.png'
      />
      <InfoContainerInv 
        youtubeUrl='https://youtu.be/dySN-9yjtkg?si=i8fZdn5bgB6saO4o'
        title='Usability Testing of Spiderman VR'
        description='For this usability testing, I used various methods to identify and fix issues and improve the user experience. I started with interviews consisting of three focus questions and follow-ups to understand the users. Then, I conducted user testing with participants matching the target profile. They tried a beta version and identified issues using the think-aloud method while completing five objectives to evaluate their interactions. Finally, I conducted a feedback interview to capture their emotions and reactions.'
        imgUrl='https://pngimg.com/d/meta_PNG5.png'
        imgUrl2='https://www.svgrepo.com/show/13671/youtube.svg'
        imgUrl3='https://assets.interaction-design.org/img/app/website-thumbnail-image.jpg?id=f765e0695d22ec022bc56bdf0e909364'
        imgUrl4='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Adobe_Illustrator_CC_icon.svg/2101px-Adobe_Illustrator_CC_icon.svg.png'
      />
      <InfoContainer 
        youtubeUrl='https://youtu.be/K9f4S_2RZQI?si=WiKLcUZ3lcTYo2sL'
        title='EduTech Usability Testing'
        description='EduTech was developed using various UX methods. First, we conducted interviews with professors and students of Universidad Autónoma del Occidente to identify our focus users. Then, we developed a prototype of the video game to verify every interaction with the user. Finally, we created a beta version of the game and used techniques such as the think-aloud method and success of objectives to evaluate it.'
        imgUrl='https://upload.wikimedia.org/wikipedia/commons/3/33/Figma-logo.svg'
        imgUrl2='https://www.svgrepo.com/show/13671/youtube.svg'
        imgUrl3='https://cdn.freelogovectors.net/wp-content/uploads/2023/09/mirologo-freelogovectors.net_.png'
        imgUrl4='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Adobe_Illustrator_CC_icon.svg/2101px-Adobe_Illustrator_CC_icon.svg.png'
      />
       <InfoContainerInv 
        youtubeUrl='https://youtu.be/WcCbDKOw2dg?si=fV-TwCnx_C5HuBJa'
        title='UX Practices on Spiderman VR'
        description='For this usability testing, I used various methods to identify and fix issues and improve the user experience. I started with interviews consisting of three focus questions and follow-ups to understand the users. Then, I conducted user testing with participants matching the target profile. They tried a beta version and identified issues using the think-aloud method while completing five objectives to evaluate their interactions. Finally, I conducted a feedback interview to capture their emotions and reactions.'
        imgUrl='https://pngimg.com/d/meta_PNG5.png'
        imgUrl2='https://www.svgrepo.com/show/13671/youtube.svg'
        imgUrl3='https://assets.interaction-design.org/img/app/website-thumbnail-image.jpg?id=f765e0695d22ec022bc56bdf0e909364'
        imgUrl4='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Adobe_Illustrator_CC_icon.svg/2101px-Adobe_Illustrator_CC_icon.svg.png'
      />
      </>
      );
}

export default UXDesigner;