import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import MenuButton from './MenuButton';
import '../styles/Navbar.css'; // Importa el archivo CSS

function Navbar() {
  const [clicked, setClicked] = useState(false);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleLinkClick = () => {
    setClicked(false); // Cierra el menú al hacer clic en un enlace
  };

  return (
    <>
      <nav>
        <h2>Diego Galán <span>Portfolio</span></h2>
        <div className={`links ${clicked ? 'active' : ''}`}>
          <Link onClick={handleLinkClick} to="/">Home</Link>
          <Link onClick={handleLinkClick} to="/gamedeveloper">Game Developer</Link>
          <Link onClick={handleLinkClick} to="/modeling">3D Modeling & Animations</Link>
          <Link onClick={handleLinkClick} to="/uxdesigner">UX Designer</Link>
          <Link onClick={handleLinkClick} to="/certifications">Certifications</Link>
          <Link onClick={handleLinkClick} to="/contact">Contact</Link>
        </div>
        <div className='burguer'>
          <MenuButton clicked={clicked} handleClick={handleClick} />
        </div>
        <div className={`bg-div ${clicked ? 'active' : ''}`}></div>
      </nav>
    </>
  );
}

export default Navbar;
