import React from 'react';
import '../styles/Card.css';
import 'bootstrap-icons/font/bootstrap-icons.css';
import { useNavigate } from 'react-router-dom';

function Card({ iconClass, frontTitle, imgSrc, backTitle, backText, targetPage }) {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(targetPage);
  };

  return (
    <div className='CardContainer' onClick={handleClick}>
      <div className='face front'>
        <i className={iconClass}></i> {/* Icono de controlador */}
        <h2 className="front-title">{frontTitle}</h2>
      </div>
      <div className='face back'>
        <img src={imgSrc} alt={backTitle} />
        <h2 className="back-title">{backTitle}</h2>
        <p>{backText}</p>
      </div>
    </div>
  );
}

export default Card;
