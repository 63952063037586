// Certifications.js
import React from 'react';
import CarouselComponent from '../components/CarouselComponent';

const Certifications = () => {
  return (
    <div className="certifications-page">
      <CarouselComponent />
    </div>
  );
};

export default Certifications;