import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Carousel } from 'react-bootstrap';

const CarouselComponent = () => {
  return (
    <Carousel variant="dark">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('../Images/UnityCertification.png')}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('../Images/C++Certification.png')}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('../Images/course-certificate-ux-design-for-virtual-reality.jpg')}
          alt="Second slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('../Images/course-certificate-affordances-designing-intuitive-user-interfaces.jpg')}
          alt="Third slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('../Images/course-certificate-conducting-usability-testing.jpg')}
          alt="First slide"
        />
      </Carousel.Item> 
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('../Images/course-certificate-emotional-design-how-to-make-products-people-will-love.jpg')}
          alt="First slide"
        />
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={require('../Images/Domestik-Diego-Galan.png')}
          alt="First slide"
        />
      </Carousel.Item>
    </Carousel>
  );
};

export default CarouselComponent;
