import React from 'react';
import '../styles/IconsAnimated.css'; // Importa el archivo CSS


function IconsAnimated() {
  return (
    <div className='IconsDiv'>
      <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png' className='Icons' alt='Logo C#'/>
      <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/1/18/ISO_C%2B%2B_Logo.svg/1822px-ISO_C%2B%2B_Logo.svg.png' className='Icons' alt='Logo C++'/>
      <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/9/99/Unofficial_JavaScript_logo_2.svg/1024px-Unofficial_JavaScript_logo_2.svg.png' className='Icons' alt='Logo javascript'/>
      <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/c/c3/Python-logo-notext.svg/1869px-Python-logo-notext.svg.png' className='Icons' alt='Logo python'/>
      <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/38/HTML5_Badge.svg/1024px-HTML5_Badge.svg.png' className='Icons' alt='Logo html'/>
      <img src='https://cdn4.iconfinder.com/data/icons/various-icons-2/476/Unity.png' className='Icons' alt='Logo unity'/>
      <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Blender_logo_no_text.svg/1024px-Blender_logo_no_text.svg.png?20210507122249' className='Icons' alt='Logo blender'/>
      <img src='https://www.svgrepo.com/show/373831/maya.svg' className='Icons' alt='Logo maya'/>
      <img src='https://cdn2.unrealengine.com/ue-logo-stacked-unreal-engine-w-677x545-fac11de0943f.png' className='Icons' alt='Logo unreal'/>
      <img src='https://www.svgrepo.com/show/303535/visual-studio-code-logo.svg' className='Icons' alt='Logo visual studio'/>
      <img src='https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Git_icon.svg/2048px-Git_icon.svg.png' className='Icons' alt='Logo git'/>
    </div>
  );
}

export default IconsAnimated;
