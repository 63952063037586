import React from 'react';
import Spline from '@splinetool/react-spline';
import '../styles/Hero.css'; // Importa el archivo CSS

function Hero() {
  return (
    <section className="HeroSection">
      <div className="TextContainer">
        <h1 className="Title">Hi, I'm Diego.</h1>
        <h2 className="Subtitle">I'm a multimedial Engineering and Let me show you what i can do!</h2>
      </div>
      <div className="SplineContainer">
      <Spline scene="https://prod.spline.design/qD40-99MOQw134DX/scene.splinecode" />
      </div>
    </section>
  );
}

export default Hero;
