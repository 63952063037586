import React from 'react';
import InfoContainer from '../components/InfoContainer';
import InfoContainerInv from '../components/InfoContainerInv';

function GameDeveloper() {
  return (
    <>
      <InfoContainer 
        youtubeUrl='https://youtu.be/R12GXrAqs9Q?si=-hWyiOCFFEuQ6D_m'
        title='Spiderman VR'
        description='The video game Spider-Man VR was created as a result of my thesis. The focus question of this project was how UX design is implemented for iconic characters. I used technologies such as Unity and Blender to create the entire 3D environment where the player will be. I worked with the C# language and Oculus Meta Quest to develop the video game. As a conclusion, I was able to develop a video game that feels intuitive and enjoyable for the user.'
        imgUrl='https://cdn4.iconfinder.com/data/icons/various-icons-2/476/Unity.png'
        imgUrl2='https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png'
        imgUrl3='https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Blender_logo_no_text.svg/1024px-Blender_logo_no_text.svg.png?20210507122249'
        imgUrl4='https://pngimg.com/d/meta_PNG5.png'
      />
      <InfoContainerInv
        youtubeUrl='https://youtu.be/rt4t8VnbK7U?si=GmdRGEMOH_YBgI-Z'
        title='Breaking Time'
        description='This video game was created in 2D. The principal idea was to make a pixel art, strategic, and time-based concept integrated into a mobile game. In this game, you will have money that you can spend to buy defenders who must protect the portal to the dimension of your kingdom. For the aesthetics, my team and I used Aseprite, and we used Unity for the development.'
        imgUrl='https://cdn4.iconfinder.com/data/icons/various-icons-2/476/Unity.png'
        imgUrl2='https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png'
        imgUrl3='https://upload.wikimedia.org/wikipedia/commons/thumb/6/69/Logo_Aseprite.svg/1200px-Logo_Aseprite.svg.png'
        imgUrl4='https://www.svgrepo.com/show/303535/visual-studio-code-logo.svg'
      />
      <InfoContainer 
        youtubeUrl='https://youtu.be/XAI9WMQBGps?si=ZDn2OLyFfNSdYuAI'
        title='The Tutankhamuns pyramid'
        description='The Tutankhamuns Pyramid is a video game designed as an escape room. In this game, you will find Tutankhamuns mummy, who tells you that you are trapped in the room with him. The only way to escape is to find his organs scattered around the room. However, you dont have much time, as the walls, armed with swords, are closing in. In this video game, I worked on the code development and all the functionalities.'
        imgUrl='https://cdn4.iconfinder.com/data/icons/various-icons-2/476/Unity.png'
        imgUrl2='https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png'
        imgUrl3='https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Blender_logo_no_text.svg/1024px-Blender_logo_no_text.svg.png?20210507122249'
        imgUrl4='https://pngimg.com/d/meta_PNG5.png'
      />
      <InfoContainerInv
        youtubeUrl='https://youtu.be/RqHrYe8fbpM?si=cY5CS052ysnWjDcK'
        title='EduTech'
        description='EduTech is the result of a final project developed with a team over the course of one year. This video game addresses the question of how virtual students at a university can practice topics such as parabolic movement. Additionally, there is a website where teachers can view students statistics on topics such as velocity, acceleration, distance, and angle. My role in this project involved implementing all functionalities related to the mathematical movements of parabolic trajectories, as well as designing all the virtual environments.'
        imgUrl='https://cdn4.iconfinder.com/data/icons/various-icons-2/476/Unity.png'
        imgUrl2='https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png'
        imgUrl3='https://pbs.twimg.com/profile_images/572884213590794240/rjH3n4XB_400x400.png'
        imgUrl4='https://www.svgrepo.com/show/303535/visual-studio-code-logo.svg'
      />
       <InfoContainer
        youtubeUrl='https://youtu.be/ODJzVpsq8CQ?si=EDOA8QfilBl_mvWi'
        title='Killing Ground'
        description='This videogame is strongly inspired by the famous video game DOOM. It is one of my first video games developed as a Multimedia Engineering project. We specifically used characters from the website Mixamo as enemies in the game. Similar to the Zombies mode in Call of Duty, you earn money by killing enemies, which you can then spend on more guns. The goal is to survive as many rounds as you can.'
        imgUrl='https://cdn4.iconfinder.com/data/icons/various-icons-2/476/Unity.png'
        imgUrl2='https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png'
        imgUrl3='https://pbs.twimg.com/profile_images/572884213590794240/rjH3n4XB_400x400.png'
        imgUrl4='https://www.svgrepo.com/show/303535/visual-studio-code-logo.svg'
      />
    </>
  );
}

export default GameDeveloper;
