import React from 'react'
import '../styles/AboutContainer.css'; // Importa el archivo CSS'
import Card from './Card';

function AboutContainer() {
  return (
    <div className="About">
    <div className="Overview_Section">
    <h2 className='SubtitleAbout'>WANNA SEE WHAT I LOVE ? </h2>
    <h1 className='TitleAbout'>OVERVIEW</h1>
    <p className='textAbout'>
          I'm passionate about Videogames. I consider myself as creative, dedicated and adaptable when technologies it's about. Also, I have great communication skills either Spanish or <a href="https://cert.efset.org/zFEyz9">English</a>🔗
    </p>
    </div>
    <div className="Cards_Section">
    <Card
        iconClass="bi bi-controller"
        frontTitle="Game Developer"
        imgSrc={require('../Images/SpidermanVR.png')}
        backTitle="Spiderman VR"
        backText="This is my own videogame about how is the life of Peter Parker behind the mask"
        targetPage="/gamedeveloper"
      />
    <Card
        iconClass="bi bi-card-heading"
        frontTitle="UX/UI Designer"
        imgSrc={require('../Images/TGE.png')}
        backTitle="Tecnology Group Education"
        backText="TGE its a app made it for childrens with TDAH, where we develop with a focus group of students"
        targetPage="/uxdesigner"
      />
    <Card
        iconClass="bi bi-window-stack"
        frontTitle="Web Developer"
        imgSrc={require('../Images/Playstation.png')}
        backTitle="Playstation Website"
        backText="This is a project that i develop using the framework React that the idea was  reply playstation website"
        targetPage="/uxdesigner"
      />
    <Card
        iconClass="bi bi-boxes"
        frontTitle="Modeling & Animation"
        imgSrc={require('../Images/CafeVitelio.png')}
        backTitle="Café Vitelio"
        backText="Café Vitelio its a 3D enviroment made it with a teamwork that you'll find a journey about how coffe its produce"
        targetPage="/modeling"
      />
    </div>

    </div>
  )
}

export default AboutContainer