import React from 'react';
import ReactPlayer from 'react-player';
import '../styles/InfoContainer.css'; // Importa el archivo CSS

function InfoContainer({ youtubeUrl, title, description, imgUrl, imgUrl2, imgUrl3, imgUrl4 }) {
  return (
    <div className='InfoDiv'>
      <div className='top_Div'>
        <h1 className='titleContainer'>{title}</h1>
      </div>
      <div className='left_Info'>
        <ReactPlayer
          className="ReactPlayerI"
          url={youtubeUrl}
          controls={false}
          playing={false}
        />
      </div>
      <div className='right_Info'>
        <div className='Top_Title'>
          <p className='DescriptionText'>{description}</p>
        </div>
        <div className='Icons_Div'>
          <img src={imgUrl} className='IconImg' alt="Project related" />
          <img src={imgUrl2} className='IconImg' alt="Project related" />
          <img src={imgUrl3} className='IconImg' alt="Project related" />
          <img src={imgUrl4} className='IconImg' alt="Project related" />
        </div>
      </div>
    </div>
  );
}

export default InfoContainer;
