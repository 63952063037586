// Modeling.js
import React from 'react';
import InfoContainer from '../components/InfoContainer';
import InfoContainerInv from '../components/InfoContainerInv';
import '../styles/Modeling.css'; // Importa el archivo CSS

function Modeling() {
    return (
      <>
        <InfoContainer 
        youtubeUrl='https://youtu.be/76d-yGGSy9I?si=3phdMTSWMOgW1iY-'
        title='Café Vitelio 3D Video'
        description='This is a project made with a team, where we chose the brand Café Vitelio to create a 3D environment in which you follow the process of making coffee. My participation in this project involved creating the coffee tree, coffee fruits, coffee paper packs, and various objects within the 3D environment. For this project, I used the program Maya to develop these models. finally we used Discord to coordinated everything'
        imgUrl='https://www.svgrepo.com/show/373831/maya.svg'
        imgUrl2='https://www.svgrepo.com/show/13671/youtube.svg'
        imgUrl3='https://www.svgrepo.com/show/353655/discord-icon.svg'
        imgUrl4='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Adobe_Illustrator_CC_icon.svg/2101px-Adobe_Illustrator_CC_icon.svg.png'
      />
       <InfoContainerInv
        youtubeUrl='https://youtu.be/xzu7gKQjags?si=6I3XSFUlRh0TUVyz'
        title='Models of Spiderman VR videogame'
        description='For these models, I aimed to make everything as realistic as possible by researching real recipes for the chemicals and modeling them accurately. I also worked with shaders to create effects such as the liquid in the bottles, and I used C# for the physics calculations. Another shader I developed was for the hologram artifact. Finally, I used Illustrator to create the labels used as materials for the bottles.'
        imgUrl='https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Blender_logo_no_text.svg/1024px-Blender_logo_no_text.svg.png?20210507122249'
        imgUrl2='https://upload.wikimedia.org/wikipedia/commons/thumb/b/bd/Logo_C_sharp.svg/1200px-Logo_C_sharp.svg.png'
        imgUrl3='https://cdn4.iconfinder.com/data/icons/various-icons-2/476/Unity.png'
        imgUrl4='https://upload.wikimedia.org/wikipedia/commons/thumb/f/fb/Adobe_Illustrator_CC_icon.svg/2101px-Adobe_Illustrator_CC_icon.svg.png'
      />
      <InfoContainer 
        youtubeUrl='https://youtu.be/6X1Z7x9DpGA?si=390aWKQtG2JbpsSh'
        title='My 3D Model Using MetaHuman'
        description='This is a project I worked on using RealityCapture. I recorded a video of myself from different angles to capture my facial features. Additionally, I used MetaHuman by Unreal Engine to blend my face with a character and then adapted the body to fit the new face. This allowed me to create a character with features similar to mine, which I used for an animation.'
        imgUrl='https://i.ytimg.com/vi/VoK0qA8BMmI/maxresdefault.jpg'
        imgUrl2='https://ue-cdn.artstation.com/imgproxy/C3U3QCwi0eIwE6HS08L_m1AFhjqgKclvNb98ysYwb9k/filename:edc_rc_thumbnail.png/resizing_type:fill/width:640/height:640/aHR0cHM6Ly9kMWl2N2RiNDR5aGd4bi5jbG91ZGZyb250Lm5ldC9pbWFnZXMvMGQ0Yjk5NmEtMzczYy00ZjIzLWFjNjItODhlMTUyOTJkMmM2L2VkY19yY190aHVtYm5haWwucG5n'
        imgUrl3='https://upload.wikimedia.org/wikipedia/commons/thumb/0/0c/Blender_logo_no_text.svg/1024px-Blender_logo_no_text.svg.png?20210507122249'
        imgUrl4='https://cdn2.unrealengine.com/ue-logo-stacked-unreal-engine-w-677x545-fac11de0943f.png'
      />
      <div className='ReferencesContainer'>
      <div className='titleReferencesContainer'>
        <h1 className='titleReferences'> Here is some images references of my works</h1>
      </div>
      <div className='imgReferenceContainer'>
      <img src={require('../Images/Arbol1.jpg')} className='imgReference' alt='Photo References'></img>
      <img src={require('../Images/Arbol2.jpg')} className='imgReference' alt='Photo References'></img>
      <img src={require('../Images/Arbol3.png')} className='imgReference' alt='Photo References'></img>
      <img src={require('../Images/Escalera1.png')} className='imgReference' alt='Photo References'></img>
      <img src={require('../Images/Escalera2.png')} className='imgReference' alt='Photo References'></img>
      <img src={require('../Images/Stitch1.png')} className='imgReference' alt='Photo References'></img>
      <img src={require('../Images/Stitch2.png')} className='imgReference' alt='Photo References'></img>
      <img src={require('../Images/WebShooter1.png')} className='imgReference' alt='Photo References'></img>
      <img src={require('../Images/WebShooter2.png')} className='imgReference' alt='Photo References'></img>
      <img src={require('../Images/Davidtoo.png')} className='imgReference' alt='Photo References'></img>
      <img src={require('../Images/Bolsas.png')} className='imgReference' alt='Photo References'></img>
      </div>
      </div>
      </>
      );
}

export default Modeling;