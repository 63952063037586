import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Navbar from './components/Navbar';
import Home from './Pages/Home';
import GameDeveloper from './Pages/GameDeveloper';
import Modeling from './Pages/Modeling';
import UXDesigner from './Pages/UXDesigner';
import Certifications from './Pages/Certifications';
import Contact from './Pages/Contact';

const ScrollToTop = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};

function App() {
  return (
    <Router>
      <ScrollToTop />
      <Navbar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/gamedeveloper" element={<GameDeveloper />} />
        <Route path="/modeling" element={<Modeling />} />
        <Route path="/uxdesigner" element={<UXDesigner />} />
        <Route path="/certifications" element={<Certifications />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
