import React from 'react'
import '../styles/TecnologiesContainer.css'; // Importa el archivo CSS
import IconsAnimated from './IconsAnimated';

function TecnologiesContainer() {
  return (
    <div className='BodyContainer'>
    <div className='TittleContainer'>
    <h2 className='SubtitleTecno'>LANGUAGES AND TOOLS</h2>
    <h1 className='TitileTecno'>HOW I DO MY PROJECTS?</h1>

    </div>
    <div className='IconsContainer'>
    <IconsAnimated></IconsAnimated>
    </div>
    </div>
  )
}

export default TecnologiesContainer