import React from 'react';
import ReactPlayer from 'react-player';
import '../styles/VideoPlayer.css'; // Importa el archivo CSS

function VideoPlayer({ youtubeUrl}) {
    return (
        <div className='ReelContainer'>
        <div className='TitleReelContainer'>
        <h1>You dont like read? well just watch </h1>
        </div>
        <div className="VideoPlayerWrapper">
          {/* Renderiza el reproductor de video de React-Player */}
          <ReactPlayer
            className="ReactPlayer"
            url={youtubeUrl}
            controls={true} // Muestra los controles del reproductor de video
            width="70%" // Ancho del reproductor (ajustado automáticamente)
            height="100%" // Alto del reproductor (ajustado automáticamente)
            playing={false} // Inicia la reproducción automáticamente
            light={false} // Muestra una imagen de vista previa en lugar del reproductor 
          />
        </div>
        </div>
        
      );
    }
    
    export default VideoPlayer;