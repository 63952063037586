// Contact.js
import React from 'react';
import Contacts from '../components/Contacts';
import '../styles/Contacts.css'; 

function Contact() {
    return (
      <>
      <div className='ContactsBody'>
        <Contacts></Contacts>
      </div>

      </>
      );
}

export default Contact;